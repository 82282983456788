<template>
	<div id="mainpage" class="flex flex-col min-h-screen leading-tight home">

		<SiteHeader />

		<section class="mt-[84px] py-24 bg-center bg-cover mainbanner-section lg:py-12">
			<div class="max-w-screen-xl px-4 mx-auto">
				<div class="flex flex-wrap -mx-4 overflow-hidden lg:items-center">
					<div class="w-full px-4 text-center lg:w-3/5 lg:text-left">
						<h3 class="text-2xl text-white lg:text-left lg:text-5xl">Professional &amp; Reliable</h3>
						<h2 class="font-semibold text-white text-3xl mb-2 lg:mb-4 lg:text-left lg:text-[4rem] lg:leading-tight">Notary Public Services</h2>
						<h3 class="text-2xl text-white Xmb-6 lg:text-left lg:text-5xl">Conveniently Located <span class="inline-block">At The Adelphi</span></h3>
						<div class="hidden pt-1 lg:block"></div>
						<!-- <div class="p-4 bg-white border-2 border-white rounded lg:py-6 lg:pl-6 lg:pr-24 md:inline-block bg-[#FFFFFFC0] text-sm text-left lg:text-xl space-y-4 lg:space-y-6">
							<p>Divorces can be difficult, but navigating Syariah Law can be challenging without the right, patient guidance.</p>
							<p>We, here at Netto &amp; Magin LLC, aim to ease that burden and give you a better understanding on your journey.</p>
						</div> -->
					</div>
					<div class="hidden w-full px-4 lg:w-2/5 lg:flex">
						<div class="w-full p-8 text-center bg-white rounded">
							<h3 class="mb-6 text-2xl font-medium">Book your Appointment<br>with us Today!</h3>
							<ContactForm/>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section id="about-us" class="text-center py-12 bg-[#E9EEF1] lg:py-20">
			<div class="max-w-screen-xl px-4 mx-auto">
				<h3 class="font-semibold text-xl text-[#0074C8] mb-3 lg:text-3xl lg:mb-4">Why Choose Netto &amp; Magin LLC</h3>
				<p class="mb-8 leading-normal lg:mb-12">We provide updated ACRA Searches – for companies notarizing their documents, we perform updated ACRA Business Profile Searches. We also handle bulk documents (Foreign company declarations, as well as foreign property documents); with Covid-19 altering the landscape, our firm has dealt with foreign documents requiring insight and knowledge.</p>
				<div class="flex flex-wrap justify-center -mx-4 overflow-hidden lg:items-stretch">
					<div class="w-full px-4 md:w-1/2 lg:w-1/3">
						<div class="px-6 py-8 mb-4 bg-white rounded lg:p-10 lg:mb-0 lg:h-full">
							<img class="mx-auto mb-4 icon-set" src="@/assets/img/icon-01.png" width="100" height="100">
							<h4 class="mb-4 text-lg font-semibold lg:text-xl">Speed <span class="block">of Service</span></h4>
							<p class="text-sm leading-normal lg:text-base">We emphasize on the ability to resolve your notarial needs within the hour, giving you time to go about the rest of your day with ease.</p>
						</div>
					</div>
					<div class="w-full px-4 md:w-1/2 lg:w-1/3">
						<div class="px-6 py-8 mb-4 bg-white rounded lg:p-10 lg:mb-0 lg:h-full">
							<img class="mx-auto mb-4 icon-set" src="@/assets/img/icon-02.png" width="100" height="100">
							<h4 class="mb-4 text-lg font-semibold lg:text-xl">Same Building As <span class="block">Singapore Academy of Law</span></h4>
							<p class="text-sm leading-normal lg:text-base">For all your notarial needs – one-stop firm for services to be rendered; being located in the vicinity to SAL, allows our clients to plan for a single trip to get their documents notarized and expeditiously authenticated.</p>
						</div>
					</div>
					<div class="w-full px-4 md:w-1/2 lg:w-1/3">
						<div class="px-6 py-8 mb-4 bg-white rounded lg:p-10 lg:mb-0 lg:h-full">
							<img class="mx-auto mb-4 icon-set" src="@/assets/img/icon-03.png" width="100" height="100">
							<h4 class="mb-4 text-lg font-semibold lg:text-xl">Experienced <span class="block">Lawyers</span></h4>
							<p class="text-sm leading-normal lg:text-base">Able to provide further advice in relation to foreign embassy requirements; with over two decades of experience in the notarial business, our Notary Public is more than capable in aiding with your individual notarial needs.</p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section id="services" class="py-12 text-center bg-white lg:py-20">
			<div class="max-w-screen-xl px-4 mx-auto">
				<h3 class="font-semibold text-xl text-[#0074C8] mb-8 lg:text-3xl lg:mb-12">Our Services</h3>
				<!-- <p class="leading-normal lg:text-lg lg:mb-12">The consultation is for us to better understand you, and you to better understand how we can assist:</p> -->
				<div class="relative flex flex-wrap -mx-4">
					<div class="right-0 flex w-full ml-auto lg:pl-4 lg:pr-14 lg:w-8/12 lg:absolute">
						<img class="lg:rounded" src="@/assets/img/img-steps.jpg" width="1458" height="970"/>
					</div>
					<div class="relative w-full px-4 -mt-12 lg:w-6/12 lg:mt-0">
						<div class="space-y-6 lg:pt-12 lg:space-y-4">

							<div class="p-6 text-left bg-[#E9EEF1] rounded">
								<div class="flex">
									<h5 class="text-5xl text-[#0074C8] leading-none lg:text-6xl">1</h5>
									<div class="mt-1 ml-4">
										<h4 class="mb-3 font-semibold leading-normal lg:text-lg">Sales and Purchase Agreements</h4>
										<p class="text-sm leading-normal lg:text-base">We provide services relating to Sales and Purchase Agreements</p>
									</div>
								</div>
							</div>

							<div class="p-6 text-left bg-[#E9EEF1] rounded">
								<div class="flex">
									<h5 class="text-5xl text-[#0074C8] leading-none lg:text-6xl">2</h5>
									<div class="mt-1 ml-4">
										<h4 class="mb-3 font-semibold leading-normal lg:text-lg">Conveyancing Documents</h4>
										<p class="text-sm leading-normal lg:text-base">We provide a variety of conveyancing documents</p>
									</div>
								</div>
							</div>

							<div class="p-6 text-left bg-[#E9EEF1] rounded">
								<div class="flex">
									<h5 class="text-5xl text-[#0074C8] leading-none lg:text-6xl">3</h5>
									<div class="mt-1 ml-4">
										<h4 class="mb-3 font-semibold leading-normal lg:text-lg">Foreign Relocation Documents</h4>
										<p class="text-sm leading-normal lg:text-base">We provide services relating to Foreign Relocation, etc.</p>
									</div>
								</div>
							</div>

							<div class="p-6 text-left bg-[#E9EEF1] rounded">
								<div class="flex">
									<h5 class="text-5xl text-[#0074C8] leading-none lg:text-6xl">4</h5>
									<div class="mt-1 ml-4">
										<h4 class="mb-3 font-semibold leading-normal lg:text-lg">On-site Notarial Services</h4>
										<p class="text-sm leading-normal lg:text-base">We also provide on-site notarial services at a competitive cost amongst our various other services</p>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="py-12 text-center bg-[#E9EEF1] lg:py-20">
			<div class="max-w-screen-xl px-4 mx-auto">
				<h3 class="font-semibold text-xl text-[#0074C8] mb-8 lg:text-3xl lg:mb-12">Process of Notarial Service</h3>
				<!-- <p class="mb-8 leading-normal lg:text-lg lg:mb-12">Whether “talak” has been pronounced or not, the man or the woman may file for a divorce in Syariah Court. <span class="lg:block">Briefly there are 5 steps in the divorce procedure:</span></p> -->
				<div class="flex flex-wrap -mx-4">
					<div class="w-full px-4">
						<div class="space-y-6 lg:space-y-4">

							<!-- <div class="p-6 bg-[#E9EEF1] rounded text-left">
								<div class="flex">
									<div class="">
										<h4 class="mb-3 font-semibold leading-normal lg:text-lg">Registration &amp; Mandatory Counselling</h4>
										<p class="text-sm leading-normal lg:text-base">To kick start the divorce process, the spouse wishing to apply for divorce in Syariah Court must first submit a Registration Form. This form is downloadable from the Syariah Court’s website. It is available in both English and Malay. Upon receiving the Form, parties will be notified to attend the Marriage Counselling Programme at one of the appointed community agencies. This is a mandatory administration of counselling.</p>
									</div>
								</div>
							</div> -->

							<div class="p-6 text-left bg-white rounded">
								<div class="flex">
									<div class="text-sm leading-normal space-y-4 lg:text-base">
										<p>Attending before the Notary Public, appending of your signature upon the necessary documents as required, then waiting for the authentication process to be completed whilst enjoying a cup of coffee before you’re good to go</p>
										<p>With dedicated staff, and the SAL being in the same building, we are able to get the job done expeditiously.</p>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="py-12 overflow-hidden lg:py-20">
			<div class="max-w-screen-xl px-4 mx-auto">
				<h3 class="text-center font-semibold text-xl text-[#0074C8] mb-8 lg:text-3xl lg:mb-12">What Our Client Say About Us</h3>
				<div class="px-4 leading-normal">
					<carousel :autoplay="true" :loop="true" :autoplayTimeout="5000" :speed="1000" :per-page="1" :mouse-drag="false" :pagination-padding="8" :navigation-enabled="true" :pagination-size="8" pagination-active-color="#0074C8" pagination-color="#e9eef1">
						<slide class="flex items-center px-4">
							<div class="bg-[#D9D9D9] p-4 rounded space-y-4">
								<p>Netto n Margin is my number one choice for preparing my legal documentations as it is a reputable, professional and efficient law firm. As a translator i need lawyers to notarise my translation Just an email n its ready Superb service ! You should too Highly recommended 👍👍👍😊</p>
								<p class="font-bold">— Normah Omar, former Supreme Court Interpreter currently providing translation, interpretation and transcription services in all Eastern &amp; Western Languages.</p>
							</div>
						</slide>
						<slide class="flex items-center px-4">
							<div class="bg-[#D9D9D9] p-4 rounded space-y-4">
								<p>Lawyer Lucy Netto is very professional with her advise to my many questions, on legal matters in regards to real estate work, LDU application for my PR clients, conveyancing work, also  prompt and quick with her services such as notary services, demand notice as needed for my many clients.</p>
								<p class="font-bold">— Client A</p>
							</div>
						</slide>
						<slide class="flex items-center px-4">
							<div class="bg-[#D9D9D9] p-4 rounded space-y-4">
								<p>I was recommended Mrs Lucy Netto for my Will and for my LPA to be notarised and sent to India.</p>
								<p>She advised me on how I could reword my LPA to be more beneficial towards me. She also took the time to explain to me how I could save costs when notarising my documents and that was very kind of her. Thanks Mrs Lucy Netto,</p>
								<p class="font-bold">— Kumar</p>
							</div>
						</slide>
						<slide class="flex items-center px-4">
							<div class="bg-[#D9D9D9] p-4 rounded space-y-4">
								<p>Mrs Lucy helped me with the Notary when I was applying to migrate to Spain. She did the notary work for my family and even took the time to explain all the documents for me to send to my lawyers in Spain.</p>
								<p class="font-bold">— Client B</p>
							</div>
						</slide>
					</carousel>
				</div>
			</div>
		</section>

    <!-- <section class="py-12 text-center text-white bg-center bg-cover bg-consultation lg:py-20">
      <div class="max-w-screen-xl px-4 mx-auto">
        <h3 class="mb-4 text-xl font-semibold lg:text-3xl">Unlike a civil divorce in the Family Justice Courts, the Syariah Court does not make any maintenance order in respect of wife (during the marriage) or the children. In Syariah Court only two types of financial provision may be granted to the wife (i.e Nafkah Iddah and Mutaah).</h3>
        <h4 class="px-6 leading-normal lg:text-xl sm:px-0">After receiving the orders made by the Learned President, if either party is dissatisfied with any of the orders, an appeal can be raised to the Syariah Appeal Board within 30 days from the date of the Order of Court.</h4>
      </div>
    </section> -->

		<section id="our-notary" class="leading-normal text-white bg-center bg-cover bg-consultation section lawyer-section">
			<div class="max-w-screen-xl px-4 py-16 mx-auto">
				<h3 class="mb-8 text-xl font-semibold text-center lg:text-3xl lg:mb-12">Our Notary</h3>
				<div class="flex flex-wrap items-center lg:flex-nowrap gap-x-16 gap-y-8">
					<div class="flex-1 w-full">
						<img class="h-auto rounded lg:w-full" src="@/assets/img/lucy-netto.jpg" alt="Lucy Netto" width="456" height="579">
					</div>
					<div class="w-full space-y-8 lg:w-7/12 lg:space-y-12">
						<h4 class="text-xl font-semibold lg:text-2xl">Lucy Netto</h4>
						<div class="space-y-4">
							<p class="text-sm leading-normal lg:text-base">Lucy Netto graduated from the University of London and was admitted to the Honourable Society of Lincoln’s Inn in 1982 and admitted as an Advocate & Solicitor of the Supreme Court of Singapore in the same year. She was appointed a Commissioner for Oaths in 1996 and as a Notary Public in 2000.</p>
							<p class="text-sm leading-normal lg:text-base">She was actively involved in legal practice from 1982 to 1994 before leaving to lecture law. Since her return, she has been in continuous active practice.</p>
							<p class="text-sm leading-normal lg:text-base">She has handled property matters, conveyancing, family matters, Probate and personal injury cases. She also does a great deal of solicitors work such as preparing contracts, wills, Powers of Attorney, amongst various other fields of law.</p>
							<p class="text-sm leading-normal lg:text-base">She represents clients in the Court of Appeal, High Court, State Courts, and Family Justice Courts on matters involving significant issues of law. She has also represented clients in both the Syariah Court and Appeal Board. She continues to be actively involved in solicitors’ and general litigation work.</p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="leading-normal bg-[#E9EEF1] section faqs-section">
			<div class="max-w-screen-xl px-4 py-16 mx-auto space-y-8 lg:space-y-12">
				<h3 class="font-semibold text-xl text-[#0074C8] lg:text-3xl text-center">FAQs</h3>
				<div class="leading-normal border-t border-black">
					<div class="border-b border-black">
						<button @click="accordions.faq_1=!accordions.faq_1;" class="flex lg:items-center w-full py-[10px] text-[21px] font-medium text-left leading-[1.19]">
							<div class="accordion-icon text-[#0074C8]">
								<div class="w-6 mr-1 icon-show" v-show="!accordions.faq_1">
									<svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" id="icon-svg-show" viewBox="0 0 100 100">
										<path d="M100 46H54V0h-8v46H0v8h46v46h8V54h46z" fill-rule="evenodd"/>
									</svg>
								</div>
								<div class="w-6 mr-1 icon-hide" v-show="accordions.faq_1">
									<svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" id="icon-svg-hide" viewBox="0 0 1250 100">
										<path d="M1250 0v100H0V0z"/>
									</svg>
								</div>
							</div>
							Can I use the original document(s) in their native language?
						</button>
						<div class="mt-4 mb-6 lg:pl-[30px] text-sm lg:text-base space-y-4" v-show="accordions.faq_1">
							<p>We require a duly translated copy in English, of the original document(s) for the purposes of authenticating them.</p>
						</div>
					</div>

					<div class="border-b border-black">
						<button @click="accordions.faq_2=!accordions.faq_2;" class="flex lg:items-center w-full py-[10px] text-[21px] font-medium text-left leading-[1.19]">
							<div class="accordion-icon text-[#0074C8]">
								<div class="w-6 mr-1 icon-show" v-show="!accordions.faq_2">
									<svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" id="icon-svg-show" viewBox="0 0 100 100">
										<path d="M100 46H54V0h-8v46H0v8h46v46h8V54h46z" fill-rule="evenodd"/>
									</svg>
								</div>
								<div class="w-6 mr-1 icon-hide" v-show="accordions.faq_2">
									<svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" id="icon-svg-hide" viewBox="0 0 1250 100">
										<path d="M1250 0v100H0V0z"/>
									</svg>
								</div>
							</div>
							Can the document be picked up on the same day?
						</button>
						<div class="mt-4 mb-6 lg:pl-[30px] text-sm lg:text-base space-y-4" v-show="accordions.faq_2">
							<p>Yes, you may, as long as the document arrives at SAL for the purposes of authentication before the end of their office hours.</p>
						</div>
					</div>

					<div class="border-b border-black">
						<button @click="accordions.faq_3=!accordions.faq_3;" class="flex lg:items-center w-full py-[10px] text-[21px] font-medium text-left leading-[1.19]">
							<div class="accordion-icon text-[#0074C8]">
								<div class="w-6 mr-1 icon-show" v-show="!accordions.faq_3">
									<svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" id="icon-svg-show" viewBox="0 0 100 100">
										<path d="M100 46H54V0h-8v46H0v8h46v46h8V54h46z" fill-rule="evenodd"/>
									</svg>
								</div>
								<div class="w-6 mr-1 icon-hide" v-show="accordions.faq_3">
									<svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" id="icon-svg-hide" viewBox="0 0 1250 100">
										<path d="M1250 0v100H0V0z"/>
									</svg>
								</div>
							</div>
							What is the difference between a legalized document and an authenticated document?
						</button>
						<div class="mt-4 mb-6 lg:pl-[30px] text-sm lg:text-base space-y-4" v-show="accordions.faq_3">
							<p>Documents for use abroad may need to go through legalization, a separate process of document authentication observed by some governments.</p>
						</div>
					</div>

					<div class="border-b border-black">
						<button @click="accordions.faq_4=!accordions.faq_4;" class="flex lg:items-center w-full py-[10px] text-[21px] font-medium text-left leading-[1.19]">
							<div class="accordion-icon text-[#0074C8]">
								<div class="w-6 mr-1 icon-show" v-show="!accordions.faq_4">
									<svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" id="icon-svg-show" viewBox="0 0 100 100">
										<path d="M100 46H54V0h-8v46H0v8h46v46h8V54h46z" fill-rule="evenodd"/>
									</svg>
								</div>
								<div class="w-6 mr-1 icon-hide" v-show="accordions.faq_4">
									<svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" id="icon-svg-hide" viewBox="0 0 1250 100">
										<path d="M1250 0v100H0V0z"/>
									</svg>
								</div>
							</div>
							When is a Notary Public required?
						</button>
						<div class="mt-4 mb-6 lg:pl-[30px] text-sm lg:text-base space-y-4" v-show="accordions.faq_4">
							<p>A Notary Public is required when witnessing the execution of an individual upon a legally binding document or attesting to the authenticity of document, that is to be used in a foreign jurisdiction. The presence of a Notary Public’s seal and signature is the primary confirmation that no fraud has been involved in the contents of the document(s).</p>
						</div>
					</div>

					<div class="border-b border-black">
						<button @click="accordions.faq_5=!accordions.faq_5;" class="flex lg:items-center w-full py-[10px] text-[21px] font-medium text-left leading-[1.19]">
							<div class="accordion-icon text-[#0074C8]">
								<div class="w-6 mr-1 icon-show" v-show="!accordions.faq_5">
									<svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" id="icon-svg-show" viewBox="0 0 100 100">
										<path d="M100 46H54V0h-8v46H0v8h46v46h8V54h46z" fill-rule="evenodd"/>
									</svg>
								</div>
								<div class="w-6 mr-1 icon-hide" v-show="accordions.faq_5">
									<svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" id="icon-svg-hide" viewBox="0 0 1250 100">
										<path d="M1250 0v100H0V0z"/>
									</svg>
								</div>
							</div>
							What are the fees?
						</button>
						<div class="mt-4 mb-6 lg:pl-[30px] text-sm lg:text-base space-y-4" v-show="accordions.faq_5">
							<p><span class="font-bold">Notary Fees are fixed.</span></p>
							<p>
								<span class="font-bold">Notarial executive to any document:</span> $40/document<br>
								<span class="font-bold">Verifying signatures:</span> $40/signature<br>
								<span class="font-bold">Certified true copies:</span> $5 for the first page, and $1 for each subsequent page<br>
								<span class="font-bold">Notarial Certificate:</span> $75 per certificate<br>
								<span class="font-bold">Execution by company or corporation with declaration and exhibit:</span> $150/-<br>
								<span class="font-bold">SAL fee:</span> $86.40<br>
							</p>
							<p>All other notarial acts not specifically mentioned in this Schedule, a notary public may charge such fee as may be considered reasonable in the circumstances.</p>
							<p><span class="font-bold">For all other fees, please call/WhatsApp or email us!</span></p>
						</div>
					</div>

				</div>
			</div>
		</section>

		<section id="contact-us" class="leading-normal bg-white section contact-section">
			<div class="max-w-screen-xl px-4 py-16 mx-auto space-y-8 lg:space-y-12">
				<h3 class="font-semibold text-xl text-[#0074C8] lg:text-3xl text-center">Contact Us</h3>
				<div class="flex flex-wrap -mx-4 gap-y-8">
					<div class="w-full px-4 lg:w-7/12">
						<p class="mb-8 text-center lg:text-xl">Book your Appointment with us Today!</p>
						<ContactForm />
					</div>
					<div class="w-full px-4 lg:w-5/12">
						<div class="bg-[#E9EEF1] px-4 py-8 h-full flex flex-col justify-center items-center gap-y-2 text-center">
							<img class="w-auto h-[50px]" src="@/assets/img/icon-phone.png" alt="Phone" width="73" height="74">
							<a class="mb-4 text-lg font-medium lg:text-2xl" href="tel:+6590658515"><span>+65 9065 8515</span></a>
							<img class="w-auto h-[50px]" src="@/assets/img/icon-whatsapp.png" alt="WhatsApp" width="89" height="90">
							<a class="mb-4 text-lg font-medium lg:text-2xl" href="https://wa.me/+6590658515?text=I%20would%20like%20to%20enquire" target="_blank">+65 9065 &zwj;8515</a>
							<a class="mb-4" href="mailto:nmllc@nmlawcorp.com.sg">nmllc@nmlawcorp.com.sg</a>
							<p>1 Coleman Street, #10-13, The Adelphi Singapore 179803</p>
						</div>
					</div>
				</div>
			</div>
		</section>


		<!-- <SiteFooter /> -->

	</div>
</template>

<script>
// @ is an alias to /src
import SiteHeader from '@/components/SiteHeader.vue'
// import SiteFooter from '@/components/SiteFooter.vue'
import ContactForm from '@/components/ContactForm.vue'
import { Carousel, Slide } from 'vue-carousel'

export default {
	name: 'index-page',
	components: {
		SiteHeader,
		// SiteFooter,
		ContactForm,
		Carousel,
		Slide,
	},
	data() {
		return {
			isOpen: 0,
			// isTop: true,
			accordions: {
				faq_1: 0,
				faq_2: 0,
				faq_3: 0,
				faq_4: 0,
				faq_5: 0,
			},
		}
	},
	// mounted () {
	// 	document.addEventListener('scroll', this.handleScroll, {passive: true});
	// },
	// methods: {
	// 	handleScroll() {
	// 		this.isTop = window.scrollY < 200;
	// 	},
	// },
}
</script>

<style scoped>
	section {
		scroll-margin-top: 57px;
	}

	.mainbanner-section {
		background-image: url('~@/assets/img/herobanner.jpg');
		background-position-x: 25%;
		@apply bg-cover lg:bg-center bg-no-repeat;
	}
	.bg-consultation {
		background-image: url('~@/assets/img/bg-consultation.jpg');
	}
	/* @screen lg {
		.mainbanner-section {
			background-size: 1580px auto !important;
		}
	} */
	.list-header li {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928z' fill='rgba(201,138,15,1)'/%3E%3C/svg%3E");
		background-position: left top;
		background-repeat: no-repeat;
		padding-left: 24px;
	}
	@screen lg {
		section {
			scroll-margin-top: 70px;
		}
		.list-header li {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928z' fill='rgba(201,138,15,1)'/%3E%3C/svg%3E");
			padding-left: 40px;
		}
	}
</style>